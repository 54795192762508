
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import PartyEntry from "@/components/PartyEntry.vue";
import { divisiones, largoFinal, territorios } from "@/modules/cyanRegions";
import { eyeOutline, eyeOffOutline, personAdd } from "ionicons/icons";
import {
  IonList,
  IonPage,
  IonItem,
  IonButton,
  IonLabel,
  IonIcon,
  IonInput,
  IonSegment,
  IonSegmentButton,
  alertController,
} from "@ionic/vue";

import unreact from "@/modules/unreact";
import cyanRequest from "@/modules/cyanRequest";

export default defineComponent({
  name: "PartyList",
  components: {
    IonList,
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonIcon,
    IonPage,
    IonSegment,
    IonSegmentButton,
    CyanPageWrapper,
    PartyEntry,
  },
  setup() {
    return {
      eyeOutline,
      eyeOffOutline,
      personAdd,
    };
  },
  data() {
    return {
      grupoSeleccionado: "",
      duiUsuarioRaw: "",
      passwordUsuario: "",
      password2Usuario: "",
      mostrarPassword: false,
      mostrarPassword2: false,
    };
  },
  mounted() {
    if (!this.conDatos) return;

    const tabAlmacenada = unreact(store.state.currentTabDirectorio);

    if (
      this.datos.groups[tabAlmacenada]
    ) {
      this.grupoSeleccionado = tabAlmacenada;
    } else {
      this.grupoSeleccionado = this.datos.groupsOrder[0];
    }
  },

  watch: {
    grupoSeleccionado() {
      store.commit("storeTabDirectorio", this.grupoSeleccionado);
    },

    duiUsuarioRaw: function () {
      if (this.duiUsuarioRaw != this.duiUsuario) {
        this.duiUsuarioRaw = this.duiUsuario;
        const el = document.getElementById("duiusuario") as any;
        if (el) {
          el.value = this.duiUsuario; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.duiUsuario;
        }
      }
    },
  },
  computed: {
    duiUsuario(): string {
      const f = this.duiUsuarioRaw.replace(/[^0-9]/g, "");
      if (f.length < 9) return f;
      return f.substr(0, 8) + "-" + f.substr(8, 1);
    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    conGrupoSeleccionado(): any {
      return !!(
        this.datos &&
        this.datos.groups &&
        this.datos.groups[this.grupoSeleccionado]
      );
    },
    datos(): any {
      const s = this.esJunta
        ? store.state.dirJunta[(this as any).$route.params.junta]
        : store.state.dirCentro[(this as any).$route.params.centro];

      if (!s || !s.ok) return {};

      return s;
    },

    datosGrupoSeleccionado(): any {
      if (
        !this.conDatos ||
        !this.datos.groups ||
        !this.datos.groups[this.grupoSeleccionado]
      )
        return {};
      return this.datos.groups[this.grupoSeleccionado];
    },

    conDatos() {
      const d = this.datos as any;
      return d && d.groupsOrder && d.groupsOrder.length;
    },

    esNacional() {
      const s = (this as any).seed;
      return s.length <= 2;
    },

    datosCentro() {
      return (this as any).datos.centro || {};
    },

    numeroJunta() {
      return (this as any).datos.junta || false;
    },

    centroJunta() {
      const j = (this as any).numeroJunta;

      let cj = j ? "Mesa " + j + ", " : "";

      if (this.datosCentro.nombre) cj += " " + this.datosCentro.nombre;

      return cj;
    },

    backRoute() {
      const u = store.state.userData.nivel || "";
      const seed = this.seed as string;

      // Si venimos de Punto Cyan, volver allí

      if (this.esPC) {
        const pp = (this as any).$route.path.split("/");
        pp[1] = "punto";
        return pp.join("/");
      }

      // Idem junta

      if (this.esA) {
        const pp = (this as any).$route.path.split("/");
        pp[1] = "agente";
        return pp.join("/");
      }

      // Si el directorio es de una junta y hay más de una junta en el centro implicado,
      // a la elección de junta

      const acj = store.state.allowedCentrosJuntas[seed];

      if (acj) {
        if (this.esJunta) {
          const j = (this as any).$route.params.junta;
          if (
            acj.datosJuntas &&
            acj.datosJuntas[j] &&
            acj.datosJuntas[j].centro_id &&
            acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id] &&
            acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id].length > 2
          )
            return "/dirPickJunta/" + seed + "/" + j;
        }

        // Si hay más de un centro, a la elección de centro

        if (acj.centros.length > 1) return "/dirPickCentro/" + seed;
      } // ACJ
      // Si el usuario tiene nivel municipal, a home; si no, a pickMpio

      if (u.length != largoFinal && divisiones[largoFinal].largoPadre)
        return "/dirPicker/" + seed.substring(0, divisiones[largoFinal].largoPadre as number);

      return "/home";
    },

    esJunta() {
      const head = (this as any).$route.path.split("/")[1];
      return head === "dirJunta" || head === "dirJuntaA";
    },

    esPC() {
      return (this as any).$route.path.split("/")[1] === "dirCentroPC";
    },
    esA() {
      return (this as any).$route.path.split("/")[1] === "dirJuntaA";
    },

    ambito() {
      const s = (this as any).seed;

      if (! (s in territorios)) return "";

      const m = territorios[s];

      if (m.nombrePadre != "" && m.nombrePadre != m.nombre)
        return m.nombre + ", " + m.nombrePadre;
      else return m.nombre;
    },
  },
  methods: {
    async createUser() {
      let msg = "";
      const _t = this as any;

      if (!this.duiUsuario.length) {
        msg += "Introduzca un DUI para el usuario. ";
      } else if (this.duiUsuario.length != 10) {
        msg += "El DUI no tiene el formato correcto. ";
      }

      if (!this.passwordUsuario) {
        msg += "Introduzca una contraseña.";
      } else if (this.passwordUsuario.length < 4) {
        msg += "La contraseña es demasiado corta.";
      } else if (this.password2Usuario !== this.passwordUsuario) {
        msg += "Las contraseñas no coinciden.";
      }

      if (msg !== "") {
        return new Promise(() => {
          alertController
            .create({
              message: msg,
              buttons: ["OK"],
            })
            .then((alert) => {
              alert.present();
            });
        });
      }

      // petición a la API + mensaje

      cyanRequest("makeVigilante/" + this.datosGrupoSeleccionado.junta, {
        needsAuth: true,
        isPost: true,
        isModal: true,
        silent: false,
        params: {
          user: this.duiUsuario,
          password: this.passwordUsuario,
        },
      }).then(function (r) {
        if (!r.ok) return;
        _t.duiUsuarioRaw = "";
        _t.passwordUsuario = "";
        _t.password2Usuario = "";
        const msg = r.message || "Se creó el usuario con éxito.";
        return new Promise(() => {
          alertController
            .create({
              message: msg as any,
              buttons: ["OK"],
            })
            .then((alert) => {
              alert.present();
            });
        });
      });
    },
  },
});
